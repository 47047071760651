import React, {useEffect, useState, useContext, useRef} from 'react'
import {Modal, Col, Tabs, Tab, Image} from 'react-bootstrap'  

//=========3RD LIBRARY========//
import _ from 'lodash' 
import moment from "moment" 
import 'react-quill/dist/quill.snow.css'

//=========ICON LIST========//   
import NotifImg from '../../img/notif.png'  
import {useLocation, useHistory} from 'react-router-dom'
import AvatarIcon from "../../img/avatar-logs.svg" 
import Logo from "../../img/logo.png"
import FolderActiveIcon from "../../img/folder.svg" 

//========CONTEXT==========//
import {DashboardContext} from "../../context/DashboardContext" 
import {NotificationManager} from 'react-notifications' 

//========CSS==========//
import  "../css/modal-custome.css"   


const ModalNotif = (props) =>{ 
    let ref = useRef()  
    const {params,getNotif,setModalSpinner,showNotif, setShowComment, setItemActive,
            setParams, dispatchChart, setSelectedWorkspace,selectedWorkspace, workspace, table, board} = useContext(DashboardContext)  
    const [notif, setNotif] = useState(false)  
    const [] = useState(false)  
    const location = useLocation()
    const history = useHistory() 
    const notifFromSetting = useRef(false) 
    const viewNotif = useRef(false) 
    const selectedItem = useRef(false) 

    useEffect(()=>{
        const getData = async() =>{
            setModalSpinner(true) 
            let result = await getNotif(params.id)
            let debouncer = setTimeout(() => {
                setModalSpinner(false)  
                if(result.status === 200) setNotif(result.data)
                else setNotif([])
              }, 500);
              return () => {
                clearTimeout(debouncer);
            } 
        } 

        if(showNotif){
             getData() 
        } 
       
    },[showNotif])

    useEffect(()=>{
        //==== Notif from setting ==== //
        if(location.state){
            selectedItem.current = location.state 
            notifFromSetting.current = true
        }
    },[location])

    useEffect(()=>{  
        if(board.length && selectedItem.current && notifFromSetting.current) getDetail(selectedItem.current)
    },[board])
 
    const clickNotif = (item) =>{ 
        let docOld = document.getElementsByClassName("bg-focus") 
        if(docOld && docOld.length){
            for(let i=0; i< docOld.length; i++){
                docOld[i].classList.remove("bg-focus")
            } 
        }
        
        let doc = document.getElementsByClassName(item.row_id) 
        if(doc && doc.length){
            doc[0].classList.add("bg-focus")
            
            doc[0].scrollIntoView({
                behavior: 'auto',
                block: 'center', 
            })
        } 
        props.onHide() 
        // if(!location.pathname.includes('setting')) getDetail(item)
        // else history.push({pathname:'/app/board/', state: item})
        
      
    }
    const getDetail = (item) =>{ 
        notifFromSetting.current = false
         //==== Notif Team =====
        if(item.type === 5) {
            history.push('/setting/teams')
            return
        } 
        selectedItem.current = item
        viewNotif.current = true
        let team_id = item.team.id
        let current_team_id = params.team.id
        if(team_id !== current_team_id){ 
            NotificationManager.error("Please switch team to " + item.team.name) 
            return
        }

        let workspace_id = item.workspace.id 
        let selected = {} 
        _.map(workspace, (val)=> {
            if(val.id === workspace_id){ 
                selected.value = val.id
                selected.label = val.name
                selected.team_id = val.team_id
                selected.created_by = val.created_by
                selected.permission_id = val.permission_id 
            }
        })  
        let boardIndex = 0
        _.map(board,(val, index)=>{ 
            if(val.id === item.board_id) boardIndex = index
        })
        dispatchChart({type : 'CLEAR_SELECTED_BOARD'})
        setParams({...params, workspace_id: selected.value, indexBoardActive: boardIndex, team_id: selected.team_id, permission_id : selected.permission_id})
        setSelectedWorkspace(selected)  
        actionDetail()
    }

    useEffect(()=>{  
        actionDetail()
       
    },[table])

    const actionDetail = () => { 
        props.onHide() 
         //====== 6 Comment, 1 Assigned, 4 Update ======// 
         if(viewNotif.current && table.length && (selectedItem.current.type === 6 || selectedItem.current.type === 1 || selectedItem.current.type === 4)){
            let debouncer = setTimeout(() => {
                setShowComment(true)
                setItemActive({id: selectedItem.current.group_id, itemId: selectedItem.current.row_id})
                viewNotif.current = false
              }, 500);
              return () => {
                clearTimeout(debouncer);
            }  
        }    
       
    }
 
    return (
        <Modal
        {...props}
        size="lg"
        id="modal-comment" 
        className="modal left fade"
        aria-labelledby="contained-modal-title-vcenter">
           
            <Modal.Header  ref={r => (ref = r)}>
                <span className="modal-title-notif">
                    Notifications
                </span> 
            </Modal.Header>
            <Modal.Body className="mt-1 ml-2 mr-2 overflow-scroll">  
            <Tabs defaultActiveKey="update" transition={false} id="tab-comment" id={notif.length}>
                <Tab eventKey="update" title="All"> 
                {notif && notif.length ===0  ?  
                    <Col> 
                        <div className="text-center p-5">
                            <h4>Ready, set, get notified!</h4>
                        </div>
                        <img src={NotifImg} width="400"></img>
                        <div className="text-center p-5">
                            <span className="font-16">Here's where you'll get notified in real-time every time someone @mention you or replies to one of your items</span>
                        </div>
                    </Col> 
                    
                    :
                    <ul className="p-0">
                        {notif && notif.map((item, key) => {
                            return (
                                <div key={key} className="devider parent-notif mt-2">
                                    <li className="notif-container">
                                        <a href="#" className="notif-href" onClick={()=> clickNotif(item)}>
                                            <div className="notif-creator center-img"> 
                                                <Image src={Logo} width="35"/>
                                            </div>
                                            <div className="notif-content">
                                                <div> 
                                                    <span className="font-14 color-grey">{moment(item.created_at).add('7','hour').fromNow()}</span>
                                                </div>
                                                <div className="mt-2">
                                                    <span className="font-14 font-w-400" dangerouslySetInnerHTML= {{__html:item.message}}></span> 
                                                </div>
                                                <div className="mt-2">
                                                    <Image src={FolderActiveIcon} className="mr-2"/>
                                                    <div className="d-inline mt-2">
                                                        <span className="font-14 text-primary">{item.board? item.board.title: ""}</span> 
                                                    </div> 
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </div>
                            )
                        })}
                        
                    </ul> 
                }
                     
                </Tab> 
            </Tabs>
            </Modal.Body>
                  
        </Modal>
    )
}

export default ModalNotif